import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, TextField, Modal } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const EditPBSModal = ({ open, handleClose, pbsData, setRender, render }) => {
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));
    const [formData, setFormData] = useState({
        linkName: '',
        linkURL: '',
        limitPerDay: '',
    });

    useEffect(() => {
        if (pbsData) {
            setFormData({ ...pbsData });
        }
    }, [pbsData]);

    const handleSave = () => {
        if (!formData.linkName || !formData.linkURL || !formData.limitPerDay ) {
            if (ToasterToggle) {
                toast.error('All fields must be filled out');
            }
            return;
        }

        axios.put(`${process.env.REACT_APP_BASE_URL}pbs/${pbsData._id}`, formData)
            .then(() => {
                if (ToasterToggle) {
                    toast.success('GMB updated successfully');
                }
                setRender(!render);
                handleClose();
            })
            .catch((error) => {
                if (ToasterToggle) {
                    toast.error('Error updating GMB');
                }
                console.error('Error updating GMB:', error);
            });
    };

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-pbs-modal-title"
                aria-describedby="edit-pbs-modal-description"
            >
                <Box sx={style}>
                    <Typography id="edit-pbs-modal-title" variant="h6" component="h2" style={{ color: "black" }}>
                        Edit GMB
                    </Typography>
                    <TextField label="Link Name" variant="outlined" fullWidth margin="normal" value={formData.linkName} onChange={(e) => setFormData({ ...formData, linkName: e.target.value })} />
                    <TextField label="Link URL" variant="outlined" fullWidth margin="normal" value={formData.linkURL} onChange={(e) => setFormData({ ...formData, linkURL: e.target.value })} />
                    <TextField label="Link Per Day" variant="outlined" fullWidth margin="normal" value={formData.limitPerDay} onChange={(e) => setFormData({ ...formData, limitPerDay: e.target.value })} />

                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleClose} sx={{ mr: 1 }}>Cancel</Button>
                        <Button onClick={handleSave} variant="contained">Save</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default EditPBSModal;

