import React, { useEffect, useState } from 'react';
// import DataTable from '../components/DataTable';
import { CircularProgress, Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import axios from "axios";
import Circular from '../components/Circular';
import { useNavigate } from 'react-router-dom';
import { CiEdit } from "react-icons/ci";
import { ToastContainer, toast } from 'react-toastify';

import { CgMoreVertical } from "react-icons/cg";
import CreatePBS from '../components/pbs/CreatePBS';
import PBSCategoriesMain from '../components/pbs/PBSCategoriesMain';
import ImportPBS from '../components/pbs/ImportPBS';
import EditPBSModal from '../components/pbs/EditPBSModal';
import { BiCategoryAlt } from 'react-icons/bi';
import ChangePBSCategoryModal from '../components/pbs/ChangePBSCategoryModal';

const AllPBS = () => {
    const URL = process.env.REACT_APP_BASE_URL;
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));
    const [data, setData] = useState([]);
    const [render, setRender] = useState(false);
    const navigate = useNavigate();
    const [passSelectedCategoryId, setPassSelectedCategoryId] = useState('all');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPBSData, setPBSData] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [assignMultipleToCategory, setAssignMultipleToCategoty] = useState([]);
    const [assignCategoryModalOpen, setAssignCategoryModalOpen] = useState(false);
    const [assignCategoryModalBulkOpen, setAssignCategoryModalBulkOpen] = useState(false);

    const [selectedPBSForCategory, setSelectedPBSForCategory] = useState(null);


    const user = localStorage.getItem("user");

    useEffect(() => {
        if (!user) {
            navigate("/login");
        }
    }, [user]);

    const handleOpenEditModal = (pbsData) => {
        setPBSData(pbsData);
        setIsEditModalOpen(true);
    };

    useEffect(() => {
        axios.get(`${URL}pbs/all`)
            .then((res) => {
                setData(res.data.pbs);
            })
            .catch((error) => console.error(error));
    }, [render]);

    const handleMenuClick = (event, row) => {
        setMenuAnchorEl(event.currentTarget);
        setCurrentRow(row);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setCurrentRow(null);
    };

    const handleEdit = () => {
        handleMenuClose();
        handleOpenEditModal(currentRow);
    };

    const onAssignToPBS = (data) => {
        setAssignMultipleToCategoty(data);
        setAssignCategoryModalBulkOpen(true);
        handleMenuClose();
    };

    const handleAssignCategory = (pbs) => {
        setSelectedPBSForCategory(pbs);
        setAssignCategoryModalOpen(true);
        handleMenuClose();
    };

    const columns = [
        { field: "_id", headerName: "ID", flex: 1, resizeable: true },
        { field: "linkName", headerName: "Link Name", flex: 2, resizeable: true },
        { field: "linkURL", headerName: "Link URL", flex: 2, resizeable: true },
        {
            field: 'timeInitiated', headerName: 'Time Initiated', flex: 2,
            renderCell: (params) => {
              const date = new Date(params.value);
              return `${String(date.getUTCHours()).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}:${String(date.getUTCSeconds()).padStart(2, '0')}`;
            }
        },      
        { field: "limitPerDay", headerName: "Limit per Day", flex: 1, resizeable: true },
        { field: "countToday", headerName: "Count Today", flex: 1, resizeable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <IconButton
                    aria-label="more"
                    onClick={(event) => handleMenuClick(event, params.row)}
                >
                    <CgMoreVertical />
                </IconButton>
            ),
        }
    ];

    const flattenPBS = data.map((row) => ({
        id: row._id,
        _id: row._id,
        linkName: row.linkName,
        linkURL: row.linkURL,
        timeInitiated: row.timeInitiated,
        limitPerDay: row.limitPerDay,
        countToday: row.countToday,
    }));

    return (
        <div style={{ width: "100%" }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <EditPBSModal
                open={isEditModalOpen}
                handleClose={() => setIsEditModalOpen(false)}
                pbsData={currentPBSData}
                setRender={setRender}
                render={render}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5">GMB</Typography>
                <CreatePBS render={render} passSelectedCategoryId={passSelectedCategoryId} setRender={setRender} />
            </div>
            {data.length > 0 ? (
                <>
                    <PBSCategoriesMain render={render} setRender={setRender} setPassSelectedCategoryId={setPassSelectedCategoryId} data={data} rows={flattenPBS
            } columns={columns} onAssignToPBS={onAssignToPBS} />

                    {/* <ImportPBS render={render} setRender={setRender} pbsCategoryId={passSelectedCategoryId} /> */}
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleEdit}>
                            <ListItemIcon>
                                <CiEdit fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                        </MenuItem>
                        {!currentRow?.isAssignCategory && (
                            <MenuItem onClick={() => handleAssignCategory(currentRow)}>
                                <ListItemIcon>
                                    <BiCategoryAlt fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Assign/Change Category</ListItemText>
                            </MenuItem>
                        )}
                    </Menu>
                </>
            ) : (
                <Circular />
            )}
            {assignCategoryModalOpen && (
                <ChangePBSCategoryModal
                    open={assignCategoryModalOpen}
                    onClose={() => setAssignCategoryModalOpen(false)}
                    link={selectedPBSForCategory}
                    setRender={setRender}
                    render={render}
                />
            )}
            {/* {assignCategoryModalBulkOpen && (
                <questionAssignCategoryModalBulk
                    open={assignCategoryModalBulkOpen}
                    onClose={() => setAssignCategoryModalBulkOpen(false)}
                    multiplequestions={assignMultipleToCategoty}
                    setRender={setRender}
                />
            )} */}
        </div>
    );
};

export default AllPBS;

