import {
    Menu,
    menuClasses,
    MenuItem,
    Sidebar,
    useProSidebar,
} from "react-pro-sidebar";
import { RxTextAlignBottom, RxDashboard, RxDragHandleHorizontal, RxCountdownTimer } from 'react-icons/rx';
import { FaGripLines, FaServer } from "react-icons/fa";
import { IoMenuOutline } from "react-icons/io5";

import { LuFileQuestion } from "react-icons/lu";

import { FaLink } from "react-icons/fa6";
import { Tooltip } from '@mui/material';

import { VscSymbolKeyword } from "react-icons/vsc";
import { MdAccountBox, MdOutlineAccountBox, MdOutlineReviews, MdOutlineSettings } from "react-icons/md";

import { BsClipboardData, BsQuestionSquareFill } from "react-icons/bs";


import { SiGmail } from "react-icons/si";
import { MdOutlineRateReview } from "react-icons/md";

import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
// import Nabl from "../Assets/Nabl.png"
function MySidebar() {

    const selectedTab = localStorage.getItem("currentTab");
    const [currentTab, setCurrentTab] = useState(selectedTab);

    // const dispatch = useDispatch();

    const { collapseSidebar } = useProSidebar();

    const navigate = useNavigate();
    const location = useLocation();
    const handleLogout = () => {
        // dispatch(logout());
        navigate("/login");
    };

    useEffect(() => {
        // Extract the pathname from location object
        const pathname = location.pathname;

        // Define your routes and corresponding tab identifiers
        const routes = [
            { path: "/all-proxies", tab: "all-proxies" },
            { path: "/all-accounts", tab: "all-accounts" },
            { path: "/create-account", tab: "create-account" },
            { path: "/create-proxy", tab: "create-proxy" },

        ];

        // Find the matching route
        const matchedRoute = routes.find((route) => pathname.startsWith(route.path));

        // Update the currentTab state with the matched tab identifier
        if (matchedRoute) {
            setCurrentTab(matchedRoute.tab);
            localStorage.setItem("currentTab", matchedRoute.tab);
        }
    }, [location]);

    return (
        <>
            <Sidebar
                // defaultCollapsed
                id="sidebar"
                style={{ height: "100vh" }}
                backgroundColor="black"
                transitionDuration={200}
                width="80px"
            // height="300px"
            >
                <Menu
                    rootStyles={{
                        [`.${menuClasses.button}`]: {
                            backgroundColor: "black",
                            color: "#ffff",
                            // ":hover": {
                            //     backgroundColor: "#ffff",
                            //     color: "rgb(58,98,225)",
                            //     border: "1px solid ",
                            //     borderRadius: "25px",
                            // },
                        },
                    }}
                >
                    <MenuItem
                        style={
                            currentTab === "logout"
                                ? {
                                    backgroundColor: "white",
                                    color: "black",
                                    borderRadius: "25px",
                                }
                                : { backgroundColor: "black", color: "white" }
                        }
                        // icon={Nabl}
                        id="logo"

                        // className=" mb-5 mt-2 text-4xl font-bold"
                        onClick={() => collapseSidebar()}
                    // disabled
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                            <IoMenuOutline
                                style={{ width: "2rem", height: "4rem" }}
                            />


                        </div>

                    </MenuItem>
                    <Link to="/all-proxies">
                        <Tooltip title="Proxies" placement="right">
                            <MenuItem
                                className=" lg:pointer-events-auto md:point sm:pointer-events-none"
                                style={
                                    currentTab === "all-proxies"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "#ffff" }
                                }
                                onClick={() => {
                                    setCurrentTab("all-proxies");
                                    localStorage.setItem("currentTab", "all-proxies");
                                }}
                                icon={<RxDashboard size={22} />}
                            >
                                Proxies
                            </MenuItem>
                        </Tooltip>
                    </Link>

                    {/* </Link> */}
                    <Link to="all-accounts">
                        <Tooltip title="Accounts" placement="right">

                            <MenuItem
                                style={
                                    currentTab === "all-accounts"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "white" }
                                }
                                onClick={() => {
                                    setCurrentTab("all-accounts");
                                    localStorage.setItem("currentTab", "all-accounts");
                                }}
                                icon={<MdAccountBox size={22} />}
                            >
                                Accounts
                            </MenuItem>
                        </Tooltip>
                    </Link>
                    <Link to="all-gmail">

                        <Tooltip title="Gmails" placement="right">
                            <MenuItem
                                style={
                                    currentTab === "all-gmail"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "white" }
                                }
                                onClick={() => {
                                    setCurrentTab("all-gmail");
                                    localStorage.setItem("currentTab", "all-gmail");
                                }}
                                icon={<SiGmail size={22} />}

                            >
                                Gmail
                            </MenuItem>
                        </Tooltip>
                    </Link>
                    <Link to="all-reviews">

                        <Tooltip title="Reviews" placement="right">
                            <MenuItem
                                style={
                                    currentTab === "all-reviews"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "white" }
                                }
                                onClick={() => {
                                    setCurrentTab("all-reviews");
                                    localStorage.setItem("currentTab", "all-reviews");
                                }}
                                icon={<MdOutlineRateReview size={22} />}

                            >
                                Reviews
                            </MenuItem>
                        </Tooltip>
                    </Link>
                    <Link to="all-specific-reviews">

                        <Tooltip title="Specific Reviews" placement="right">
                            <MenuItem
                                style={
                                    currentTab === "all-specific-review"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "white" }
                                }
                                onClick={() => {
                                    setCurrentTab("all-specific-review");
                                    localStorage.setItem("currentTab", "all-specific-review");
                                }}
                                icon={<MdOutlineReviews size={22} />}

                            >
                                Specific Reviews
                            </MenuItem>
                        </Tooltip>
                    </Link>

                    <Link to="review-logs">

                        <Tooltip title="Reviews Logs" placement="right">
                            <MenuItem
                                style={
                                    currentTab === "review-logs"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "white" }
                                }
                                onClick={() => {
                                    setCurrentTab("review-logs");
                                    localStorage.setItem("currentTab", "review-logs");
                                }}
                                icon={<BsClipboardData size={22} />}

                            >
                                Review Logs
                            </MenuItem>
                        </Tooltip>
                    </Link>
                    <Link to="all-questions">

                        <Tooltip title="Questions" placement="right">
                            <MenuItem
                                style={
                                    currentTab === "all-questions"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "white" }
                                }
                                onClick={() => {
                                    setCurrentTab("all-questions");
                                    localStorage.setItem("currentTab", "all-questions");
                                }}
                                icon={<BsQuestionSquareFill size={22} />}

                            >
                                Questions
                            </MenuItem>
                        </Tooltip>
                    </Link>
                    <Link to="all-gmb">

                    <Tooltip title="GMB" placement="right">
                        <MenuItem
                            style={
                                currentTab === "all-gmb"
                                    ? {
                                        backgroundColor: "#1B3C73",
                                        color: "white",
                                        borderRadius: "25px",
                                    }
                                    : { backgroundColor: "black", color: "white" }
                            }
                            onClick={() => {
                                setCurrentTab("all-gmb");
                                localStorage.setItem("currentTab", "all-gmb");
                            }}

                            icon={<RxCountdownTimer size={22} />}

                        >
                            Questions
                        </MenuItem>
                    </Tooltip>
                    </Link>
                    <Link to="question-logs">

                        <Tooltip title="Question Logs" placement="right">
                            <MenuItem
                                style={
                                    currentTab === "question-logs"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "white" }
                                }
                                onClick={() => {
                                    setCurrentTab("question-logs");
                                    localStorage.setItem("currentTab", "question-logs");
                                }}
                                icon={<LuFileQuestion size={22} />}

                            >
                                Question Logs
                            </MenuItem>
                        </Tooltip>
                    </Link>

                    <Link to="links">
                        <Tooltip title="Links" placement="right">

                            <MenuItem
                                style={
                                    currentTab === "links"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "white" }
                                }
                                onClick={() => {
                                    setCurrentTab("links");
                                    localStorage.setItem("currentTab", "links");
                                }}
                                icon={<FaLink size={22} />}
                            >
                                Links
                            </MenuItem>
                        </Tooltip>
                    </Link>
                    <Link to="keywords">
                        <Tooltip title="Keywords" placement="right">

                            <MenuItem
                                style={
                                    currentTab === "keywords"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "white" }
                                }
                                onClick={() => {
                                    setCurrentTab("keywords");
                                    localStorage.setItem("currentTab", "keywords");
                                }}
                                icon={<VscSymbolKeyword size={22} />}
                            >
                                Keywords
                            </MenuItem>
                        </Tooltip>
                    </Link>
                    <Link to="vms">
                        <Tooltip title="VMs" placement="right">

                            <MenuItem
                                style={
                                    currentTab === "vms"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "white" }
                                }
                                onClick={() => {
                                    setCurrentTab("vms");
                                    localStorage.setItem("currentTab", "vms");
                                }}
                                icon={<FaServer size={22} />}


                            >
                                VMs
                            </MenuItem>
                        </Tooltip>
                    </Link>
                    <Link to="setting">
                        <Tooltip title="Settings" placement="right">

                            <MenuItem
                                style={
                                    currentTab === "setting"
                                        ? {
                                            backgroundColor: "#1B3C73",
                                            color: "white",
                                            borderRadius: "25px",
                                        }
                                        : { backgroundColor: "black", color: "white" }
                                }
                                onClick={() => {
                                    setCurrentTab("setting");
                                    localStorage.setItem("currentTab", "setting");
                                }}
                                icon={<MdOutlineSettings size={22} />}
                            >
                                Settings
                            </MenuItem>
                        </Tooltip>
                    </Link>
                    {/* <Link to="create-account">
                        <MenuItem
                            style={
                                currentTab === "create-account"
                                    ? {
                                        backgroundColor: "white",
                                        color: "black",
                                        borderRadius: "25px",
                                    }
                                    : { backgroundColor: "black", color: "white" }
                            }
                            onClick={() => {
                                setCurrentTab("create-account");
                                localStorage.setItem("currentTab", "create-account");
                            }}
                            icon={<IoMdRecording />}
                        >
                            Create Account
                        </MenuItem>
                    </Link>
                    <Link to="create-proxy">
                        <MenuItem
                            style={
                                currentTab === "create-proxy"
                                    ? {
                                        backgroundColor: "white",
                                        color: "black",
                                        borderRadius: "25px",
                                    }
                                    : { backgroundColor: "black", color: "white" }
                            }
                            onClick={() => {
                                setCurrentTab("create-proxy");
                                localStorage.setItem("currentTab", "create-proxy");
                            }}
                            icon={<RxDragHandleHorizontal />}
                        >
                            Create Proxy
                        </MenuItem>
                    </Link> */}
                </Menu>
            </Sidebar>
        </>
    );
}

export default MySidebar;

