import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import { RiDeleteBin6Line } from "react-icons/ri";
import { CgMoreVertical } from 'react-icons/cg';
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { MdAssignmentAdd } from 'react-icons/md';

const SpecificReviewCategoriesTable = ({ categories, onCategorySelect, handleDeleteCategory, handleAssignPoolSpecificReviewCategory }) => {
    const allCategories = [{ _id: 'all', name: 'All Reviews' }, ...categories];

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const columns = [
        {
            field: 'name',
            headerName: 'Category Name',
            flex: 3,
        },
        {
            field: 'actions',
            headerName: '',
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton
                        onClick={(event) => handleOpenMenu(event, params.row)}
                        sx={{ cursor: 'pointer', color: 'blue', fontSize: '1.3rem' }}
                    >
                        <CgMoreVertical />
                    </IconButton>
                </>
            ),
        },
    ];

    const rows = allCategories.map((category) => ({
        id: category._id,
        name: category.categoryName || category.name,
        poolSpecificReviewCategories: category.poolSpecificReviewCategories,
        isPoolSpecificReviewCategoryAssigned: category.isPoolSpecificReviewCategoryAssigned, 
    }));

    const handleRowClick = (params) => {
        onCategorySelect(params.id);
    };

    const handleOpenMenu = (event, category) => {
        setAnchorEl(event.currentTarget);
        setSelectedCategory(category);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setSelectedCategory(null);
    };

    const handleMenuAction = (action) => {
        switch (action) {
            case 'delete':
                handleDeleteCategory(selectedCategory.id);
                break;
            case 'assign':
                handleAssignPoolSpecificReviewCategory(selectedCategory);
                break;
            default:
                break;
        }
        handleCloseMenu();
    };

    return (
        <div
            style={{
                height: '370px',
                width: '100%',
                overflow: 'hidden',
                border: 'none',
                resize: 'vertical',
                minHeight: '200px'
            }}

        // style={{ height: 370, width: '100%', overflow: 'hidden', border: 'none' }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                pagination
                onRowClick={handleRowClick}
                hideFooter
                sx={{
                    fontFamily: 'Poppins, sans-serif',
                    '& .MuiDataGrid-columnHeaders': {
                        color: 'white', // White text for headers
                        fontSize: '1.25rem', // Increase font size
                        borderBottom: 'solid',
                    },
                    '& .MuiDataGrid-row': {
                        backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#2D3748',
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#4A5568 !important',
                            '&:hover': {
                                backgroundColor: '#4A5568',
                            },
                        },
                    },
                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-displayedRows': {
                        color: 'white',
                    },
                    '& .MuiDataGrid-root': {
                        boxShadow: 'none',
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={() => handleMenuAction('delete')}>
                    <ListItemIcon>
                        <RiDeleteBin6Line fontSize="large" />
                    </ListItemIcon>
                    <ListItemText> Delete</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleMenuAction('assign')}>
                    <ListItemIcon>
                        <MdAssignmentAdd fontSize="large" />
                    </ListItemIcon>
                    <ListItemText> Assign/Change Pool Category</ListItemText>

                </MenuItem>
                {selectedCategory?.isPoolSpecificReviewCategoryAssigned &&
                    <Typography backgroundColor={'#D3D3D3'} p={2}>P/Category {selectedCategory?.poolSpecificReviewCategories[0]?.categoryName}</Typography>
                }
            </Menu>
        </div>
    );
};

export default SpecificReviewCategoriesTable;

