import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Allproxies from "./pages/Allproxies";
import MySidebar from "./pages/Sidebar/MySidebar";
import AllAccounts from "./pages/AllAccounts";
import AllGmail from "./pages/AllGmail";
import Login from "./pages/Login";
import CheckToken from "./pages/CheckToken";
import Links from "./pages/Links";
import Keywords from "./pages/Keywords";
import AllSetting from "./pages/AllSetting";
import AllVMs from "./pages/AllVMs";
import AllReviews from "./pages/AllReview";
import AllReviewLogs from "./pages/AllReviewLogs";
import AllQuestion from "./pages/AllQuestion";
import AllQuestionLogs from "./pages/AllQuestionLogs";
import AllSpecificReviews from "./pages/AllSpecificReview";
import AllPBS from "./pages/AllPBS";


function App() {
  const gradientStyle = {
    background: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
    height: '100vh',
    width: '100%',
    color: "white"
  };
  const location = useLocation();
  const isLoginPage = location.pathname.includes('/login');

  return (
    <div style={{ display: "flex" }} >
      {!isLoginPage && <MySidebar />}
      <div style={{ ...gradientStyle, flex: 1, padding: "5px 20px", color: "white" }}>
        <Routes>
          <Route path="/" element={<CheckToken />} />
          <Route path="/login" element={<Login />} />
          <Route path="/all-proxies" element={<Allproxies />} />
          <Route path="/all-accounts" element={<AllAccounts />} />
          <Route path="/links" element={<Links />} />
          <Route path="/keywords" element={<Keywords />} />
          <Route path="/setting" element={<AllSetting />} />
          <Route path="/all-gmail" element={<AllGmail />} />
          <Route path="/all-reviews" element={<AllReviews />} />
          <Route path="/all-specific-reviews" element={<AllSpecificReviews />} />
          <Route path="/review-logs" element={<AllReviewLogs />} />
          <Route path="/all-questions" element={<AllQuestion />} />
          <Route path="/all-gmb" element={<AllPBS />} />
          <Route path="/question-logs" element={<AllQuestionLogs />} />
          <Route path="/vms" element={<AllVMs />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;

