import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, FormControlLabel, Checkbox, Typography } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const CreatePBS = ({ render, setRender }) => {
    const [open, setOpen] = useState(false);
    const [PBSText, setPBSText] = useState('');
    const [limitPerDay, setLimitPerDay] = useState('');
    const [linkURL, setLinkURL] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [attachCategory, setAttachCategory] = useState(false);

    const [errors, setErrors] = useState({});

    const URL = process.env.REACT_APP_BASE_URL;
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));

    const handleClickOpen = () => {
        setOpen(true);
        fetchCategories();
    };

    const handleClose = () => {
        setOpen(false);
        setPBSText('');
        setLinkURL('');
        setLimitPerDay('')
        setSelectedCategory('');
        setAttachCategory(false); 
    };

    const validateFields = () => {
        let tempErrors = {};
        let errorMessages = [];

        if (attachCategory && !selectedCategory) {
            tempErrors.selectedCategory = "Please select a category or uncheck 'Attach to Category'";
            errorMessages.push("Please select a category or uncheck 'Attach to Category'");
        }

        setErrors(tempErrors);
        return errorMessages.length === 0 ? true : errorMessages;
    };
    // Fetch categories for the dropdown
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${URL}pbs-category/all`);
            setCategories(response.data);
        } catch (error) {
            toast.error('Failed to fetch categories');
            console.error('Error fetching categories:', error);
        }
    };

    // Handle form submission
    const handleSubmit = async () => {
        const validationErrors = validateFields();

        if (validationErrors !== true) {
            // Show each validation error in a toast
            validationErrors.forEach((error) => {
                toast.error(error);  // Display each error message in the toast
            });
            return;
        }
        const pbsData = {
            linkName:PBSText,
            linkURL,
            limitPerDay
        };

        if (attachCategory && selectedCategory) {
            pbsData.category = selectedCategory;
        }

        try {
            const response = await axios.post(`${URL}pbs/create`, pbsData);
            if (ToasterToggle) {
                toast.success(response.data.message || 'GMB created successfully');
            }
            setRender((prev) => !prev);
            handleClose();
        } catch (error) {
            toast.error('Failed to create GMB');
            console.error('Error creating GMB:', error);
        }
    };

    return (
        <div>
            <Button style={{ margin: "2rem 3rem" }} variant="contained" color="primary" onClick={handleClickOpen}>
                Create GMB
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create a New GMB</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Link Name"
                        fullWidth
                        variant="outlined"
                        value={PBSText}
                        onChange={(e) => setPBSText(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Limit Per Day"
                        fullWidth
                        variant="outlined"
                        value={limitPerDay}
                        onChange={(e) => setLimitPerDay(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Link URL"
                        fullWidth
                        variant="outlined"
                        value={linkURL}
                        onChange={(e) => setLinkURL(e.target.value)}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={attachCategory}
                                onChange={(e) => setAttachCategory(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Attach to Category"
                    />

                    {attachCategory && (
                        <TextField
                            select
                            label="Select Category"
                            fullWidth
                            margin="dense"
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                        >
                            {categories.map((category) => (
                                <MenuItem key={category._id} value={category._id}>
                                    {category.categoryName}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default CreatePBS;

