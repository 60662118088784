import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, IconButton } from '@mui/material';
import { FaPlay, FaRocket } from "react-icons/fa";
import { FaStop } from "react-icons/fa";
import { GoRocket } from "react-icons/go";

const DataTable = ({ rows, columns, onDeleteSelected, onAssignToProxy, showAssignToCategory, showCategoryStart, showStartAndEndProcess, onBulkStart, onBulkStop, onCategoryStart, totalPages, currentPage, handlePageChange, totalItems, selectedCategoryId, columnVisibilityModel, saveColumnVisibility, showAllAccountStart, allAccountStart }) => {
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    return (
        <div style={{ width: '100%', height: "70vh" }}>

            <DataGrid
                key={selectedCategoryId || 0}  // Add a unique key based on the selected category
                rows={rows}
                columns={columns}
                pageSize={100}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                paginationMode="server"
                rowCount={totalItems}
                page={currentPage - 1}
                onPaginationModelChange={(newPage) => {
                    handlePageChange(newPage.page + 1); // Handle the page change (convert to 1-based index)
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => saveColumnVisibility(newModel)}
                // disableColumnResize={false}
                pagination

                sx={{
                    fontFamily: 'Poppins, sans-serif',
                    '& .MuiDataGrid-columnHeaders': {
                        color: 'white',
                        fontSize: '1.25rem',
                        alignSelf: "flex-end"
                    },
                    '& .MuiButtonBase-root': {
                        color: 'white',
                    },
                    '& .MuiDataGrid-row': {
                        backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#2D3748',
                        },
                    },
                    '& .MuiDataGrid-footerContainer': {
                        backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                        color: 'white',
                    },
                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-displayedRows': {
                        color: 'white',
                    },
                    '& .MuiDataGrid-cell': {
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& .super-app-theme--header': {
                        '.MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                            textAlign: 'center',
                            flex: 1,
                            justifyContent: 'center',
                        },
                    },
                    '& .MuiCheckbox-root svg': {
                        color: '#2b9fdb',
                    },
                }}
            />
            <Button
                variant="contained"
                color="error"
                onClick={() => onDeleteSelected(rowSelectionModel)}
                disabled={rowSelectionModel.length === 0}
                sx={{ mt: 2 }}
            >
                Delete Selected
            </Button>

            {showAssignToCategory && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onAssignToProxy(rowSelectionModel)}
                    disabled={rowSelectionModel.length === 0}
                    sx={{ mt: 2, ml: 2 }}
                >
                    Assign to Category
                </Button>
            )}

            {showStartAndEndProcess && (
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => onBulkStart(rowSelectionModel)}
                    disabled={rowSelectionModel.length === 0}
                    sx={{ mt: 2, ml: 2 }}
                    endIcon={<FaPlay />}
                >
                    Start Selected
                </Button>
            )}


            {showStartAndEndProcess && (
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => onBulkStop(rowSelectionModel)}
                    disabled={rowSelectionModel.length === 0}
                    sx={{ mt: 2, ml: 2 }}
                    endIcon={<FaStop />}
                >
                    Stop Selected
                </Button>
            )}

            {showCategoryStart && (
                <Button
                    variant="contained"
                    color="info"
                    onClick={() => onCategoryStart()}
                    sx={{ mt: 2, ml: 2 }}
                    endIcon={<FaRocket />}
                >
                    Start Category Accounts
                </Button>
            )}

            {showAllAccountStart && (
                <Button
                    variant="contained"
                    color="info"
                    onClick={() => allAccountStart()}
                    sx={{ mt: 2, ml: 2 }}
                    endIcon={<GoRocket />}
                >
                    Start All Accounts
                </Button>
            )}
        </div>
    );
}


export default DataTable;

