import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { CgMoreVertical } from 'react-icons/cg';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CiEdit } from 'react-icons/ci';
import { MdAssignmentAdd } from 'react-icons/md';

const AccountCategoriesTable = ({ categories, onCategorySelect, handleDeleteCategory, handleEditCategory, handleAssignReviewCategory, handleAssignQuestionCategory, handleAssignSpecificReviewCategory , handleAssignPoolSpecificReviewCategory }) => {
    const allCategories = [{ _id: 'all', categoryName: 'All Accounts' }, ...categories];

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const columns = [
        {
            field: 'name',
            headerName: 'Category Name',
            flex: 4,
        },
        {
            field: 'actions',
            headerName: '',
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton
                        onClick={(event) => handleOpenMenu(event, params.row)}
                        sx={{ cursor: 'pointer', color: 'blue', fontSize: '1.3rem' }}
                    >
                        <CgMoreVertical />
                    </IconButton>
                </>
            ),
        },
    ];

    const rows = allCategories.map((category) => ({
        id: category._id,
        name: category.categoryName,
        reviewCategories: category.reviewCategories,
        isReviewCategoryAssigned: category.isReviewCategoryAssigned,
        questionCategories: category.questionCategories,
        isQuestionCategoryAssigned: category.isQuestionCategoryAssigned,
        specificReviewCategories: category.specificReviewCategories,
        isSpecificReviewCategoryAssigned: category.isSpecificReviewCategoryAssigned,
        poolSpecificReviewCategories: category.poolSpecificReviewCategories,
        isPoolSpecificReviewCategoryAssigned: category.isPoolSpecificReviewCategoryAssigned,
    }));

    const handleOpenMenu = (event, category) => {
        setAnchorEl(event.currentTarget);
        setSelectedCategory(category);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setSelectedCategory(null);
    };

    const handleMenuAction = (action) => {
        switch (action) {
            case 'edit':
                handleEditCategory(selectedCategory);
                break;
            case 'delete':
                handleDeleteCategory(selectedCategory.id);
                break;
            case 'assign':
                handleAssignReviewCategory(selectedCategory);
                break;
            case 'assignQuestion':
                handleAssignQuestionCategory(selectedCategory);
                break;
            case 'assignSpecific':
                handleAssignSpecificReviewCategory(selectedCategory)
            case 'assignPoolSpecific':
                handleAssignPoolSpecificReviewCategory(selectedCategory)
            default:
                break;
        }
        handleCloseMenu();
    };

    return (
        <div
            // style={{ height: 370, width: '100%', overflow: 'hidden', border: 'none' }}
            style={{
                height: '370px',
                width: '100%',
                overflow: 'hidden',
                border: 'none',
                resize: 'vertical',
                minHeight: '200px'
            }}

        >
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                onRowClick={(params) => onCategorySelect(params.id)}
                hideFooter
                sx={{
                    fontFamily: 'Poppins, sans-serif',
                    '& .MuiDataGrid-columnHeaders': {
                        color: 'white',
                        border: 'none',
                        fontSize: '1.25rem',
                        borderBottom: 'Solid',
                    },
                    '& .MuiDataGrid-row': {
                        backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#2D3748',
                        },
                        border: 'none',
                        '&.Mui-selected': {
                            backgroundColor: '#4A5568 !important',
                            '&:hover': {
                                backgroundColor: '#4A5568',
                            },
                        },
                    },
                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-displayedRows': {
                        color: 'white',
                    },
                    '& .MuiDataGrid-root': {
                        boxShadow: 'none',
                        border: 'none',
                    },
                }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={() => handleMenuAction('edit')}>
                    <ListItemIcon>
                        <CiEdit fontSize="large" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleMenuAction('delete')}>
                    <ListItemIcon>
                        <RiDeleteBin6Line fontSize="large" />
                    </ListItemIcon>
                    <ListItemText> Delete</ListItemText>
                </MenuItem>
                {/* {!selectedCategory?.isReviewCategoryAssigned && */}
                <MenuItem onClick={() => handleMenuAction('assign')}>
                    <ListItemIcon>
                        <MdAssignmentAdd fontSize="large" />
                    </ListItemIcon>
                    <ListItemText> Assign/Change Review Category</ListItemText>

                </MenuItem>
                {selectedCategory?.isReviewCategoryAssigned &&
                    <Typography backgroundColor={'#D3D3D3'} p={2}> R/Category : {selectedCategory?.reviewCategories[0]?.categoryName}</Typography>
                }
                <MenuItem onClick={() => handleMenuAction('assignSpecific')}>
                    <ListItemIcon>
                        <MdAssignmentAdd fontSize="large" />
                    </ListItemIcon>
                    <ListItemText> Assign/Change Specific Review Category</ListItemText>
                </MenuItem>
                {selectedCategory?.isSpecificReviewCategoryAssigned &&
                    <Typography backgroundColor={'#D3D3D3'} p={2}>S-R/Category {selectedCategory?.specificReviewCategories[0]?.categoryName}</Typography>
                }
                <MenuItem onClick={() => handleMenuAction('assignPoolSpecific')}>
                    <ListItemIcon>
                        <MdAssignmentAdd fontSize="large" />
                    </ListItemIcon>
                    <ListItemText> Assign/Change Pool Category</ListItemText>
                </MenuItem>
                {selectedCategory?.isPoolSpecificReviewCategoryAssigned &&
                    <Typography backgroundColor={'#D3D3D3'} p={2}>P/Category {selectedCategory?.poolSpecificReviewCategories[0]?.categoryName}</Typography>
                }
                <MenuItem onClick={() => handleMenuAction('assignQuestion')}>
                    <ListItemIcon>
                        <MdAssignmentAdd fontSize="large" />
                    </ListItemIcon>
                    <ListItemText> Assign/Change Question Category</ListItemText>
                </MenuItem>
                {selectedCategory?.isQuestionCategoryAssigned &&
                    <Typography backgroundColor={'#D3D3D3'} p={2}>Q/Category {selectedCategory?.questionCategories[0]?.categoryName}</Typography>
                }
            </Menu>
        </div>
    );
};

export default AccountCategoriesTable;

