import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import { RiDeleteBin6Line } from "react-icons/ri";

const PBSCategoriesTable = ({ categories, onCategorySelect, handleDeleteCategory }) => {
    const allCategories = [{ _id: 'all', name: 'All Questions' }, ...categories];

    const columns = [
        {
            field: 'name',
            headerName: 'Category Name',
            flex: 3,
        },
        {
            field: 'actions',
            headerName: '',
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton
                        onClick={() => handleDeleteCategory(params.id)}
                        sx={{ cursor: 'pointer', color: 'error.main', fontSize: "1.3rem" }}
                    >
                        <RiDeleteBin6Line />
                    </IconButton>
                </>
            ),
        },
    ];

    const rows = allCategories.map((category) => ({
        id: category._id,
        name: category.categoryName || category.name,
    }));

    const handleRowClick = (params) => {
        onCategorySelect(params.id);
    };

    return (
        <div
            style={{
                height: '370px',
                width: '100%',
                overflow: 'hidden',
                border: 'none',
                resize: 'vertical',
                minHeight: '200px'
            }}

        // style={{ height: 370, width: '100%', overflow: 'hidden', border: 'none' }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                pagination
                onRowClick={handleRowClick}
                hideFooter
                sx={{
                    fontFamily: 'Poppins, sans-serif',
                    '& .MuiDataGrid-columnHeaders': {
                        color: 'white', // White text for headers
                        fontSize: '1.25rem', // Increase font size
                        borderBottom: 'solid',
                    },
                    '& .MuiDataGrid-row': {
                        backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#2D3748',
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#4A5568 !important',
                            '&:hover': {
                                backgroundColor: '#4A5568',
                            },
                        },
                    },
                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-displayedRows': {
                        color: 'white',
                    },
                    '& .MuiDataGrid-root': {
                        boxShadow: 'none',
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                }}
            />
        </div>
    );
};

export default PBSCategoriesTable;

