import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';

const ImportSpecificReview = ({ render, setRender, reviewCategoryId }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setError('');
    };

    const handleFileUpload = async () => {
        try {
            if (!file) {
                setError('Please select a file.');
                return;
            }

            const formData = new FormData();
            formData.append('file', file);
            formData.append('reviewCategoryId', reviewCategoryId);

            const URL = process.env.REACT_APP_BASE_URL;
            await axios.post(`${URL}specific-review/upload-review`, formData);

            if (ToasterToggle) {
                toast.success('All Specific Reviews Imported SuccessFully');
            }
            // Reset the file input
            setFile(null);
            // Clear the file input value
            document.getElementById('fileInput').value = null;

            // Trigger re-render or update data as needed
            setError('');
            setRender(!render);
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Error uploading file. Please try again.');
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0px', padding: '10px' }}>
            <label style={{ marginRight: '10px' }}>Choose File:</label>
            <input type="file" id="fileInput" onChange={handleFileChange} />
            <Button variant="contained" style={{ backgroundColor: 'Black', marginLeft: '10px' }} onClick={handleFileUpload}>
                Upload File
            </Button>

            {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
        </div>
    );
};

export default ImportSpecificReview;

